import { useRef, useState, useEffect, RefObject, FC, forwardRef } from 'react';
import { FaColumns, FaRegUser, FaSignOutAlt } from 'react-icons/fa';
import Text from '../../core/utils/Text';
import { CLOSE_SIDEBAR_SIZE } from '../../core/constants/common';
import { useWindowSize } from '../../core/hooks/useWindowSize';
import useClickOutside from '../../core/hooks/useClickOutside';
import { routes } from 'core/constants/routes';
import { motion } from 'framer-motion';
import DgSidebarMenu from 'styles/UI/SidebarMenu/DgSidebarMenu';
import { useLogoutQuery } from 'core/hooks/react-query/useLogoutQuery';
import { MdAdminPanelSettings } from 'react-icons/md';
import { VscLayersActive } from 'react-icons/vsc';
import useACL from 'core/services/role/useACL';
import { IoWalletOutline } from 'react-icons/io5';
import { AiOutlineDollarCircle, AiOutlineInfoCircle } from 'react-icons/ai';
import { ImBlogger2, ImTree } from 'react-icons/im';
import { BsCalculator, BsFillCartPlusFill } from 'react-icons/bs';
import { BiRecycle, BiTransfer } from 'react-icons/bi';
import DgButton from 'styles/UI/Button/DgButton';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import clsx from 'clsx';
import { IoIosNotificationsOutline } from 'react-icons/io';

interface ISideBar {
	windowSize: any;
	isOpen: boolean;
	setIsMenuOpen: (param: any) => void;
}

type Props = Record<string, any>;

const Sidebar: FC<ISideBar> = ({ windowSize, isOpen, setIsMenuOpen }) => {
	// hooks
	const sideRef: RefObject<HTMLDivElement> = useRef(null);
	useClickOutside(sideRef, () => {
		setIsMenuOpen(false);
	});

	// store
	const { isEnglish } = useSelector((store: RootState) => store.settingStore);

	//states
	const [open, setOpen] = useState(false);

	//hooks
	const { width } = useWindowSize();

	console.log({ CLOSE_SIDEBAR_SIZE }, windowSize.width < CLOSE_SIDEBAR_SIZE);

	//effects
	useEffect(() => {
		width < 1024 && open && setOpen(false);
		width > 1024 && !open && setOpen(true);
	}, [width]);

	const variants = {
		in: {
			x: 0,
			transition: { duration: 0.3 },
		},
		out: {
			x: isEnglish ? '100vw' : '-100vw',
			transition: { duration: 0.3 },
		},
	};

	return (
		<>
			{windowSize.width < CLOSE_SIDEBAR_SIZE ? (
				<motion.div
					variants={variants}
					initial='out'
					animate={windowSize.width < CLOSE_SIDEBAR_SIZE && (isOpen ? 'in' : 'out')}
					className={clsx(
						'bg-[var(--dg-white-color)] dark:bg-[var(--header-bg)]',
						isEnglish ? 'right-0' : 'left-0',

						// 'min-w-[280px] max-w-[280px] z-[999] lg-969::sticky fixed top-[90px]'
						'fixed top-[90px] w-[80vw] h-[90vh] z-[9999999999999999999999999999] pb-12'
					)}
				>
					<SideBody key={'vertical'} type='vertical' ref={sideRef} />
				</motion.div>
			) : (
				<div className='min-w-[280px] max-w-[280px] z-[999] sticky bg-[var(--dg-white-color)] dark:bg-[var(--header-bg)] h-[calc(100vh-150px)] xs:h-[calc(100vh-90px)] top-[90px] left-0'>
					<SideBody key={'vertical'} type='vertical' ref={sideRef} />
				</div>
			)}
		</>
	);
};

const SideBody = forwardRef<HTMLDivElement, Props>(({ type = 'vertical' }, ref) => {
	const isVertical = type === 'vertical';
	const isHorizontal = type === 'horizontal';

	console.log({ isVertical, isHorizontal });

	// query
	const { refetch: logout, isFetching: isFetchingLogout } = useLogoutQuery('sidebar');

	// hooks
	const { roles, hasAccessTo } = useACL();

	const navigations = [
		// {navKey:routes, name: 'main', to: routes.MAIN, role: true, icon: <FaEquals /> },
		{
			navKey: routes.DASHBOARD,
			name: 'dashboard',
			to: routes.DASHBOARD,
			role: true,
			icon: <FaColumns color='var(--dg-opposite-color)' size={20} />,
		},
		{
			name: 'admin-panel',
			icon: <MdAdminPanelSettings color='var(--dg-opposite-color)' size={22} />,
			subMenu: [
				{
					navKey: routes.ADMIN_USER,
					name: 'user-management',
					to: routes.ADMIN_USER,
					role: hasAccessTo(roles.user?.admin),
				},
				{
					navKey: routes.ADMIN_ORDER,
					name: 'invests',
					to: routes.ADMIN_ORDER,
					role: hasAccessTo(roles.order?.admin),
				},
				{
					navKey: routes.ADMIN_PLANS_COMMISSIONS(),
					name: 'profits',
					to: routes.ADMIN_PLANS_COMMISSIONS(),
					role: hasAccessTo(roles.mlm?.admin),
				},
				{
					navKey: routes.ADMIN_ROLE,
					name: 'roles',
					to: routes.ADMIN_ROLE,
					role: hasAccessTo(roles.superAdmin),
				},
				{
					navKey: routes.ADMIN_INVOICE,
					name: 'invoices',
					to: routes.ADMIN_INVOICE,
					role: hasAccessTo(roles.superAdmin),
				},
				{
					navKey: routes.ADMIN_WITHDRAWS,
					name: 'withdraws',
					to: routes.ADMIN_WITHDRAWS,
					role: hasAccessTo(roles.superAdmin),
				},
				{
					navKey: routes.ADMIN_USERS_ACTIVITY,
					name: 'users-activity',
					to: routes.ADMIN_USERS_ACTIVITY,
					role: hasAccessTo(roles.user?.admin),
				},
				{
					navKey: routes.ADMIN_ACCOUNTING,
					name: 'accounting',
					to: routes.ADMIN_ACCOUNTING,
					role: hasAccessTo(roles.user?.admin),
				},
				{
					navKey: routes.ADMIN_USERS_TRANSACTIONS,
					name: 'users-transactions',
					to: routes.ADMIN_USERS_TRANSACTIONS,
					role: hasAccessTo(roles.user?.admin),
				},
				// { navKey: routes.ADMIN_USERS_HISTORY, name: 'users-history', to: routes.ADMIN_USERS_HISTORY, role: hasAccessTo(roles.user?.admin) },
				{
					navKey: routes.ADMIN_PACKAGE,
					name: 'package-management',
					to: routes.ADMIN_PACKAGE,
					role: hasAccessTo(roles.package?.admin),
				},
				{
					navKey: routes.ADMIN_BANNERS,
					name: 'banners',
					to: routes.ADMIN_BANNERS,
					role: hasAccessTo(roles.superAdmin),
				},
				{
					navKey: routes.ADMIN_BONUS,
					name: 'bonus',
					to: routes.ADMIN_BONUS,
					role: hasAccessTo(roles.superAdmin),
				},
				{
					navKey: routes.ADMIN_BALANCE_PROFIT,
					name: 'balance-profit',
					to: routes.ADMIN_BALANCE_PROFIT,
					role: hasAccessTo(roles.superAdmin),
				},
				{
					navKey: routes.ADMIN_WALLET_SETTINGS,
					name: 'wallet-settings',
					to: routes.ADMIN_WALLET_SETTINGS,
					role: hasAccessTo(roles.superAdmin),
				},
				{
					navKey: routes.ADMIN_SETTINGS,
					name: 'settings',
					to: routes.ADMIN_SETTINGS,
					role: hasAccessTo(roles.superAdmin),
				},
				{
					navKey: routes.ADMIN_MESSAGES,
					name: 'messages',
					to: routes.ADMIN_MESSAGES,
					role: hasAccessTo(roles.superAdmin),
				},
			],
		},
		{
			navKey: routes.ORDER_SUBMIT_STEPS.key,
			name: 'package-purchase',
			to: routes.ORDER_SUBMIT_STEPS.link('package-buy-step-1'),
			role: true,
			icon: <BsFillCartPlusFill color='var(--dg-opposite-color)' size={20} />,
		},
		{
			navKey: routes.PROFILE,
			name: 'profile',
			to: routes.PROFILE,
			role: true,
			icon: <FaRegUser color='var(--dg-opposite-color)' size={20} />,
		},
		{
			navKey: routes.ORDERS_LIST,
			name: 'my-invest',
			to: routes.ORDERS_LIST,
			role: true,
			icon: <VscLayersActive color='var(--dg-opposite-color)' size={20} />,
		},
		{
			navKey: routes.PLANS_COMMISSIONS(),
			name: 'my-profits',
			to: routes.PLANS_COMMISSIONS(),
			role: true,
			icon: <AiOutlineDollarCircle color='var(--dg-opposite-color)' size={20} />,
		},
		{
			navKey: routes.BINARY_TREE,
			name: 'binary-tree',
			to: routes.BINARY_TREE,
			role: true,
			icon: <ImTree color='var(--dg-opposite-color)' size={20} />,
		},
		// {
		// 	navKey: routes.USER_WITHDRAW_LIST,
		// 	name: 'withdraw-list',
		// 	to: routes.USER_WITHDRAW_LIST,
		// 	role: true,
		// 	icon: <IoWalletOutline color='var(--dg-opposite-color)' size={20} />,
		// },

		{
			navKey: routes.WALLETS,
			name: 'wallets',
			to: routes.WALLETS,
			role: true,
			icon: <IoWalletOutline color='var(--dg-opposite-color)' size={20} />,
		},
		{
			navKey: routes.ACCOUNTING,
			name: 'accounting',
			to: routes.ACCOUNTING,
			role: true,
			icon: <BsCalculator color='var(--dg-opposite-color)' size={20} />,
		},
		// { navKey: routes.TRANSACTION, name: 'transactions-history', to: routes.TRANSACTION, role: true, icon: <AiOutlineTransaction color='var(--dg-opposite-color)' size={20} /> },
		// {
		// 	navKey: routes.TRANSFER,
		// 	name: 'inter-user-transfer',
		// 	to: routes.TRANSFER,
		// 	role: true,
		// 	icon: <BiTransfer color='var(--dg-opposite-color)' size={20} />,
		// },
		{
			navKey: routes.INTERNAL_TRANSFER,
			name: 'internal-transfer',
			to: routes.INTERNAL_TRANSFER,
			role: true,
			icon: <BiRecycle color='var(--dg-opposite-color)' size={20} />,
		},

		{
			navKey: routes.NOTIFICATIONS,
			name: 'messages',
			to: routes.NOTIFICATIONS,
			role: true,
			icon: <IoIosNotificationsOutline color='var(--dg-opposite-color)' size={20} />,
		},

		// {
		// 	navKey: routes.TERMS,
		// 	openTab: true,
		// 	name: 'terms',
		// 	to: routes.TERMS,
		// 	role: true,
		// 	icon: <HiOutlineDocumentText color='var(--dg-opposite-color)' size={20} />,
		// },
		// {
		// 	navKey: routes.ABOUT,
		// 	openTab: true,
		// 	name: 'about-mlm',
		// 	to: routes.ABOUT,
		// 	role: true,
		// 	icon: <AiOutlineInfoCircle color='var(--dg-opposite-color)' size={20} />,
		// },
		// {
		// 	navKey: routes.BLOG,
		// 	openTab: true,
		// 	name: 'mlm-blog',
		// 	to: routes.BLOG,
		// 	role: true,
		// 	icon: <ImBlogger2 color='var(--dg-opposite-color)' size={20} />,
		// },
	];

	return (
		<>
			{isVertical && (
				<div
					key={type}
					className='h-full flex flex-col overflow-y-auto md:justify-between items-center'
					ref={ref}
				>
					<div className='flex flex-col w-full'>
						{navigations.map((nav, index) => (
							<DgSidebarMenu type={type} key={index + type} {...nav} />
						))}
					</div>

					<div className='px-7 text-center w-full'>
						<DgButton
							danger
							ghost
							onClick={() => logout()}
							isLoading={isFetchingLogout}
							className='my-3 w-full'
							customTitle={
								<div className='flex items-center gap-3 justify-center'>
									<FaSignOutAlt className='flex items-center' />
									<Text tid='exit' />
								</div>
							}
						/>
					</div>
				</div>
			)}
			{isHorizontal && (
				<div key={type} className='w-screen' ref={ref}>
					<div className='flex flex-row w-full overflow-x-auto'>
						{navigations.map((nav, index) => (
							<DgSidebarMenu type={type} key={index + type} {...nav} />
						))}
					</div>
				</div>
			)}
		</>
	);
});

SideBody.displayName = 'SideBody';

export default Sidebar;
