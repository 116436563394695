import { routes } from 'core/constants/routes';
import Text from 'core/utils/Text';
import React from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import DgAlert from 'styles/UI/Alert/DgAlert';
import DgButton from 'styles/UI/Button/DgButton';

const Google2faAlert = () => {
	const navigate = useNavigate();
	return (
		<DgAlert
			onClick={() => navigate(routes.PROFILE_SECURITY_ACTIVE_2FA)}
			className='bg-[var(--dg-red-color-2)] cursor-pointer border-0 lg-600:flex-row flex-col gap-5'
			message={
				<p className='flex gap-x-3 m-0 items-center flex-col md:flex-row'>
					<span>
						<AiFillCloseCircle size={20} />
					</span>
					<Text
						tid='please-active-totp-verification'
						className='text-xs md:whitespace-nowrap'
					/>
				</p>
			}
			// action={
			// 	<DgButton
			// 		title='totp-verification'
			// 		onClick={() => navigate(routes.PROFILE_SECURITY_ACTIVE_2FA)}
			// 	/>
			// }
		/>
	);
};

export default Google2faAlert;
