import { FC } from 'react';
import { BsCheckCircleFill, BsFillCircleFill } from 'react-icons/bs';
import { IPackage } from 'core/types/models/packageType';
import Text from 'core/utils/Text';
import DgButton from 'styles/UI/Button/DgButton';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import useTranslate from 'core/utils/useTranslate';
import DgPrice from 'styles/UI/Price/DgPrice';

interface IPackageItem {
	item: IPackage;
	selected: boolean;
	onClick: () => void;
	queryType: 'package_id' | 'category_id';
}

const PackageItem: FC<IPackageItem> = ({ item, queryType, selected, onClick }) => {
	const { lang } = useSelector((store: RootState) => store.settingStore);
	const { setUrlParam } = useQueryUrlParam();
	const { t } = useTranslate();
	const {
		name,
		src,
		roi_percentage,
		validity_in_days,
		price,
		id,
		binary_percentage,
		category,
		direct_percentage,
		indirect_percentages,
		short_name,
	} = item;

	return (
		<div
			onClick={() => {
				setUrlParam({ [queryType]: id });
				onClick();
			}}
			className={` ${
				selected ? 'border-dg-info-color border' : ' border border-transparent'
			} items-center relative flex flex-col p-1.5 md:p-4 rounded-lg cursor-pointer hover:shadow-xl dark:bg-dg-card-bg-3 bg-dg-card-bg-1 shadow-lg`}
		>
			{selected && (
				<>
					<BsCheckCircleFill
						className={`text-dg-info-color z-10 ${
							lang === 'fa' ? 'left-4' : 'right-4'
						}  top-4 absolute text-2xl`}
					/>
					<BsFillCircleFill
						className={`text-dg-white-color ${
							lang === 'fa' ? 'left-[13px]' : 'right-[13px]'
						} top-[13px] absolute text-3xl`}
					/>
				</>
			)}
			<img width={120} src={src} />
			<h1 className='text-dg-white-color text-2xl my-10'>{name}</h1>

			<div className='grid gap-5 w-full px-4'>
				<div className='flex justify-between items-center border-b border-b-[var(--dg-border-color)] pb-2'>
					<Text className='font-bold text-xs md:text-base' tid='price' />
					<span className='font-bold text-base md:text-xl'>
						<DgPrice price={price} />
					</span>
				</div>

				<div className='flex justify-between items-center border-b border-b-[var(--dg-border-color)] pb-2'>
					<Text
						hintTitle='invest-profit'
						hintDescription='roi_percentage_info'
						className='font-bold text-xs md:text-base'
						tid='invest-profit'
					/>
					<span className='font-bold text-base md:text-xl'>{roi_percentage}%</span>
				</div>

				<div className='flex justify-between items-center border-b border-b-[var(--dg-border-color)] pb-2'>
					<Text className='font-bold text-xs md:text-base' tid='invest-release' />
					<span className='font-bold text-base md:text-xl'>
						{validity_in_days} {t('days')}
					</span>
				</div>

				{/* <div className='flex justify-between items-center border-b border-b-[var(--dg-border-color)] pb-2'>
					<Text className='font-bold text-xs md:text-base' tid='direct_percentage' />
					<span className='font-bold text-base md:text-xl'>{direct_percentage}%</span>
				</div> */}

				{/* <div className='flex justify-between items-center border-b border-b-[var(--dg-border-color)] pb-2'>
					<Text
						className='font-bold text-xs md:text-base'
						tid='binary_percentage'
						hintTitle='binary_percentage'
						hintDescription='binary_percentage_info'
					/>
					<span className='font-bold text-base md:text-xl'>{binary_percentage}%</span>
				</div> */}

				<DgButton
					onClick={() => setUrlParam({ [queryType]: id })}
					className='my-5'
					type={selected ? 'primary' : 'default'}
					title='select-package'
				/>
			</div>
		</div>
	);
};

export default PackageItem;
