import 'react-loading-skeleton/dist/skeleton.css';
import 'text-security/text-security.css';
import './assets/css/index.scss';
import './core/theme/ant-config.less';
import './core/theme/antdConfigure.css';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './core/reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import './config';
import i18n from 'core/i18n';
import { I18nextProvider } from 'react-i18next';

ReactDOM.render(
	<I18nextProvider i18n={i18n} defaultNS={'translation'}>
		<Router basename={process.env.PUBLIC_URL}>
			<App />
		</Router>
	</I18nextProvider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
