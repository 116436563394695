import useACL from 'core/services/role/useACL';
import useTranslate from 'core/utils/useTranslate';
import React, { useEffect, useMemo, useState } from 'react';
import { FiSend } from 'react-icons/fi';
import DgButton from 'styles/UI/Button/DgButton';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import DgModal from 'styles/UI/Modal/DgModal';
import DgTextAreaLabel from 'styles/UI/TextArea/DgTextAreaLabel';
import DgTooltip from 'styles/UI/Tooltip/DgTooltip';
import useSendNotificationWidget from './hooks/useSendNotificationWidget';
import { useWindowSize } from 'core/hooks/useWindowSize';
import { CLOSE_SIDEBAR_SIZE } from 'core/constants/common';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import DgSelectLabel from 'styles/UI/Select/DgSelectLabel';
import { useAdminUserMutation } from 'components/Admin/User/List/hooks/react-query/useAdminUserMutation';
import { useDebounce } from 'core/hooks/useDebounce';
import { Checkbox } from 'antd';
import Text from 'core/utils/Text';

const SendNotificationWidget = () => {
	const {
		hideModal,
		isLoadingSendMessage,
		modal,
		onSendNotificationHandler,
		showModal,
		form,
		debouncedSearchQuery,
		isGlobal,
		searchQuery,
		selectedUser,
		setIsGlobal,
		setSearchQuery,
		setSelectedUser,
	} = useSendNotificationWidget();

	const { roles, hasAccessTo } = useACL();
	const { isAuthenticated } = useSelector((store: RootState) => store.mainInfoStore);
	const { t } = useTranslate();
	const { width } = useWindowSize();

	const { mutate, isLoading, data } = useAdminUserMutation(
		() => {},
		[searchQuery],
		`email=${debouncedSearchQuery}`
	);

	const searchResult = useMemo(() => {
		if (!data?.data?.data?.list?.length) {
			return [];
		}

		return data.data.data.list.map((item: any) => ({
			label: item.email,
			value: item.id,
		}));
	}, [data?.data]);

	useEffect(() => {
		if (debouncedSearchQuery?.length) {
			mutate({
				email: debouncedSearchQuery,
			});
		}
	}, [debouncedSearchQuery]);

	return (
		<>
			<DgForm className='w-full' onFinish={onSendNotificationHandler} form={form}>
				<DgFormWrapper singleColumn>
					<DgFormItem
						name='title'
						rules={[{ required: true, message: t('title-is-required') }]}
					>
						<DgInputLabel label='title' required />
					</DgFormItem>

					<DgFormItem
						name='body'
						rules={[{ required: true, message: t('message-is-required') }]}
					>
						<DgTextAreaLabel label='message' required />
					</DgFormItem>

					<DgFormItem>
						<Checkbox
							checked={isGlobal}
							onChange={(e) => {
								setIsGlobal(e.target.checked);
							}}
							className='text-black dark:text-white'
						>
							<Text tid={'global'} />
						</Checkbox>
					</DgFormItem>

					{!isGlobal && (
						<DgFormItem
							name={'user_id'}
							rules={[{ required: true, message: t('select-an-user') }]}
						>
							<DgSelectLabel
								// disabled={isLoading}
								loading={isLoading}
								onChange={(value, option) => {
									setSelectedUser(value);
								}}
								onSearch={(value) => {
									setSearchQuery(value);
								}}
								value={selectedUser}
								label={'user'}
								options={searchResult?.length ? searchResult : []}
								searchValue={searchQuery}
								autoClearSearchValue={false}
								filterOption={() => true}
							/>

							{/* <DgInputLabel label='title' required /> */}
						</DgFormItem>
					)}

					<DgFormItem button>
						<DgButton title='send' className='w-full' isLoading={isLoadingSendMessage} />
					</DgFormItem>
				</DgFormWrapper>
			</DgForm>
		</>
	);
};

export default SendNotificationWidget;
