import ChangePasswordForm from 'components/Profile/components/ProfileSecurity/components/ChangePasswordForm';
import TransactionPassword from 'components/Profile/components/ProfileSecurity/components/TransactionPassword';
import useProfileSecurity from 'components/Profile/hooks/useProfileSecurity';
import SponsorIdCreateForm from 'components/SponsorId/SponsorIdCreateForm';
import useModal from 'core/hooks/useModal';
import Text from 'core/utils/Text';
import React from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import DgAlert from 'styles/UI/Alert/DgAlert';
import DgModal from 'styles/UI/Modal/DgModal';

const TransactionPasswordAlert = () => {
	const { modal, showModal, hideModal } = useModal();

	const {
		hideTransactionPasswordModal,
		modalTransactionPassword,
		showTransactionPasswordModal,
		onFinishTransactionPassword,
		transactionPasswordForm,
		transactionPasswordIsLoading,
		onFinishUserPassword,
		userPasswordForm,
		userPasswordIsLoading,
	} = useProfileSecurity();

	return (
		<>
			<DgAlert
				className='bg-[var(--dg-red-color-2)] h-auto cursor-pointer border-0 lg-600:flex-row flex-col gap-5'
				message={
					<p className='flex gap-x-3 m-0 items-center flex-col md:flex-row'>
						<span>
							<FiAlertCircle size={22} color='var(--dg-white-color)' />
						</span>
						<Text
							tid='please-enter-your-transaction-password'
							className='text-xs text-[var(--dg-white-color)]'
						/>
					</p>
				}
				onClick={showModal}

				// action={<TransactionPassword type={'raw'} />}
			/>

			<DgModal open={modal} onCancel={hideModal}>
				<ChangePasswordForm
					onFinishTransactionPassword={onFinishTransactionPassword}
					transactionPasswordForm={transactionPasswordForm}
					transactionPasswordIsLoading={transactionPasswordIsLoading}
					onFinishUserPassword={onFinishUserPassword}
					userPasswordForm={userPasswordForm}
					userPasswordIsLoading={userPasswordIsLoading}
					isUserPassword={false}
				/>
			</DgModal>
		</>
	);
};

export default TransactionPasswordAlert;
