import icon1 from 'assets/images/Currency-bro.svg';
import icon2 from 'assets/images/Bitcoin-rafiki.svg';
import PackageItem from './components/PackageItem';
import usePackageList from './hooks/usePackageList';
import DgSpinnerFull from 'styles/UI/Spinner/DgSpinnerFull';
import { useEffect } from 'react';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';

const PackageList = ({
	queryType,
	next = () => {},
}: {
	queryType: 'package_id' | 'category_id';
	next?: () => void;
}) => {
	const { query } = useQueryUrlParam();

	const { packageList, selectedType, selectedTypeHandler, isFetchingPackageList } =
		usePackageList({ queryType });

	const newPackageList = packageList?.data.map((item, index) => ({
		...item,
		// src: index === 0 ? icon1 : icon2,
		src: icon1,
	}));

	useEffect(() => {
		if (query?.package_id) {
			setTimeout(next, 200);
		}
	}, [query]);

	// console.log({ selectedType, query });

	return (
		<DgSpinnerFull isLoading={isFetchingPackageList}>
			<div className='grid grid-cols-1 lg-500:grid-cols-2 lg-1200:grid-cols-3 gap-5 min-h-[300px] max-w-6xl w-full mx-auto'>
				{newPackageList?.map((item) => (
					<PackageItem
						queryType={queryType}
						key={item.id}
						onClick={() => {
							selectedTypeHandler(item.id, item);

							// next();
						}}
						selected={selectedType.index === item.id}
						item={item}
					/>
				))}
			</div>
		</DgSpinnerFull>
	);
};

export default PackageList;
