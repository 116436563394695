import { UseMutationResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import api from 'core/services/fetch-api/useApi';
import {
	setAuthenticated,
	setHas2FA,
	setToken,
} from 'core/store/slice/mainInfo/mainInfoSlice';
import { useDispatch } from 'react-redux';
import Cookie from 'js-cookie';
import toastHandler from 'core/helpers/toast/toast';
import useTranslate from 'core/utils/useTranslate';

export const useLoginQuery = () => {
	const dispatch = useDispatch();
	const { t } = useTranslate();

	return useMutationHook(
		[endPointUrls.LOGIN],
		(data: any) => api.post(endPointUrls.LOGIN, data),
		{
			// cacheTime: 0,
			onSuccess: (res: any) => {
				if (res?.status === 200) {
					if (res?.data?.data?.google2fa_enable === 1) {
						dispatch(setHas2FA(true));
						dispatch(setToken(res?.data?.data?.access_token));
					} else {
						Cookie.set('jwt', res?.data?.data?.access_token);
						dispatch(setAuthenticated(true));
						toastHandler('success', t(res?.data.message));
					}
				}
			},
		}
	) as UseMutationResult;
};
