import { FaWallet, FaColumns } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { routes } from 'core/constants/routes';
import { BsFillCartPlusFill } from 'react-icons/bs';
import { VscLayersActive } from 'react-icons/vsc';
import { isSelectedItemRoute } from 'core/utils/utils';
import Text from 'core/utils/Text';

const BottomBar = () => {
	const { pathname } = useLocation();

	const tabs = [
		{
			name: 'dashboard',
			icon: (
				<FaColumns
					size={18}
					color={
						isSelectedItemRoute(routes.DASHBOARD, pathname, false)
							? 'var(--dg-primary-color)'
							: 'var(--dg-primary-color)'
					}
					className='mx-auto mb-1'
				/>
			),
			to: routes.DASHBOARD,
			key: routes.DASHBOARD,
		},
		{
			name: 'package-purchase',
			icon: (
				<BsFillCartPlusFill
					size={18}
					color={
						isSelectedItemRoute(routes.ORDER_SUBMIT_STEPS.key, pathname, false)
							? 'var(--dg-primary-color)'
							: 'var(--dg-primary-color)'
					}
					className='mx-auto mb-1'
				/>
			),
			key: routes.ORDER_SUBMIT_STEPS.key,
			to: routes.ORDER_SUBMIT_STEPS.link('package-buy-step-1'),
		},
		{
			name: 'my-invest',
			icon: (
				<VscLayersActive
					size={18}
					color={
						isSelectedItemRoute(routes.ORDERS_LIST, pathname, false)
							? 'var(--dg-primary-color)'
							: 'var(--dg-primary-color)'
					}
					className='mx-auto mb-1'
				/>
			),
			to: routes.ORDERS_LIST,
			key: routes.ORDERS_LIST,
		},
		{
			name: 'my-wallet',
			icon: (
				<FaWallet
					size={18}
					color={
						isSelectedItemRoute(routes.WALLETS, pathname, false)
							? 'var(--dg-primary-color)'
							: 'var(--dg-primary-color)'
					}
					className='mx-auto mb-1'
				/>
			),
			to: routes.WALLETS,
			key: routes.WALLETS,
		},
	];

	return (
		<div className='fixed bottom-0 w-full h-[60px] bg-[var(--dg-card-bg-2)] shadow-[-2px_0_4px_var(--dg-opposite-color) py-[10px] px-[10px] z-[9999999]'>
			<div className='flex items-center justify-between'>
				{tabs.map((tab, idx) => (
					<div className='flex flex-col items-center h-10' key={idx}>
						<Link
							onClick={() => {
								window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
							}}
							to={tab.to}
						>
							{tab.icon}
							<div
								className={`text-xs ${
									isSelectedItemRoute(tab.key, pathname, false)
										? 'text-[var(--dg-primary-color)]'
										: 'text-[var(--dg-primary-color)]'
								}`}
							>
								<Text tid={tab.name} />
							</div>
						</Link>
					</div>
				))}
			</div>
		</div>
	);
};

export default BottomBar;
