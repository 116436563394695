import AdminSettings from 'components/Admin/Settings/AdminSettings';
import SendNotificationWidget from 'components/SendNotificationWidget/SendNotificationWidget';
import Text from 'core/utils/Text';
import { FC } from 'react';
import DgCard from 'styles/UI/Card/DgCard';

const AdminMessagesPage: FC = () => {
	return (
		<>
			<div className='flex items-center justify-center w-full'>
				<div className='max-w-xl w-full'>
					<DgCard className='flex flex-col gap-4'>
						<span>
							<Text tid='send-message' className='font-bold text-2xl' />
						</span>

						<SendNotificationWidget />
					</DgCard>
				</div>
			</div>
		</>
	);
};

export default AdminMessagesPage;
