// import OrderSubmit from 'components/OrderSubmitCategoryPrice/OrderSubmit';
import GoldOrderSubmit from 'components/GoldOrderSubmit/GoldOrderSubmit';
// import OrderSubmit from 'components/OrderSubmitCategoryPrice/OrderSubmit';
import OrderSubmit from 'components/OrderSubmitPackage/OrderSubmit';
import { routes } from 'core/constants/routes';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import { RootState } from 'core/store';
import Text from 'core/utils/Text';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DgButton from 'styles/UI/Button/DgButton';
import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import DgPrice from 'styles/UI/Price/DgPrice';

const OrderSubmitPage = () => {
	const { pathnameLastPart, query, filterParams } = useQueryUrlParam();

	const navigate = useNavigate();

	const { userWallet } = useSelector((store: RootState) => store.walletStore);

	const tabs = [
		{
			key: 'normal-investment',
			label: <Text tid='normal-investment' />,
			children: (
				<>
					<OrderSubmit />
				</>
			),
		},
		// {
		// 	key: 'gold-investment',
		// 	label: <Text tid='gold-investment' />,
		// 	children: (
		// 		<>
		// 			<GoldOrderSubmit />
		// 		</>
		// 	),
		// },
	];
	const [activeTab, setActiveTab] = useState(tabs[0].key);

	useEffect(() => {
		// navigate(routes.ORDER_SUBMIT_STEPS.link(`package-buy-step-1?${filterParams}`));
		navigate(routes.ORDER_SUBMIT_STEPS.link('package-buy-step-1'));
	}, [activeTab]);

	return (
		<div>
			<DgCard>
				<DgCardHeader
					tabOptions={{ tabs, activeTab, onChange: setActiveTab }}
					title='package-purchase'
					customButton={
						<div className='flex items-center gap-10'>
							<span className='flex flex-col'>
								<Text tid='my-balance' className='text-[var(--dg-orange-color)]' />
								<DgPrice price={userWallet?.balance} />
							</span>
							<DgButton
								title='deposit-wallets'
								onClick={() => navigate(routes.WALLETS)}
							/>
						</div>
					}
				/>
			</DgCard>
		</div>
	);
};

export default OrderSubmitPage;
