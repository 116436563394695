import SponsorIdCreateForm from 'components/SponsorId/SponsorIdCreateForm';
import useModal from 'core/hooks/useModal';
import Text from 'core/utils/Text';
import React from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import DgAlert from 'styles/UI/Alert/DgAlert';
import DgModal from 'styles/UI/Modal/DgModal';

const SponsorIdAlert = () => {
	const { modal, showModal, hideModal } = useModal();

	return (
		<>
			<DgAlert
				className='bg-[var(--dg-red-color-2)] cursor-pointer border-0 lg-600:flex-row flex-col gap-5'
				message={
					<p className='flex gap-x-3 m-0 items-center flex-col md:flex-row'>
						<span>
							<FiAlertCircle size={22} color='var(--dg-white-color)' />
						</span>
						<Text
							tid='please-enter-your-referral-code'
							className='text-xs text-[var(--dg-white-color)]'
						/>
					</p>
				}
				onClick={showModal}
				// action={<SponsorIdCreateForm />}
			/>

			<DgModal open={modal} onCancel={hideModal}>
				<SponsorIdCreateForm />
			</DgModal>
		</>
	);
};

export default SponsorIdAlert;
