import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import DgPagination from 'styles/UI/Pagination/DgPagination';
import DgTable from 'styles/UI/Table/DgTable';
import { useUserInternalTransferHistoryQuery } from '../hooks/react-query/useUserInternalTransferHistoryQuery';
import UserInternalTransferTableColumn from './UserInternalTransferTableColumn';

const UserInternalTransferTable = () => {
	const { data: transfers, isFetching: transferIsFetching } =
		useUserInternalTransferHistoryQuery();

	const { query, handlePagination } = useQueryUrlParam();

	// // console.log({ transfers });

	return (
		<>
			<DgTable
				rowKey='id'
				columns={UserInternalTransferTableColumn()}
				dataSource={transfers?.data?.list}
				count={transfers?.data?.pagination?.total}
				loading={transferIsFetching}
				current={transfers?.data?.pagination?.current_page}
				per_page={query?.per_page}
			/>
			<DgPagination
				onPaginationHandler={handlePagination}
				responsive={true}
				per_page={query?.per_page}
				current={transfers?.data?.pagination?.current_page}
				total={transfers?.data?.pagination?.total}
			/>
		</>
	);
};

export default UserInternalTransferTable;
