import { Button, Popover } from 'antd';
import Text from 'core/utils/Text';
import useTranslate from 'core/utils/useTranslate';
import { useState } from 'react';

export const ConfirmationPopover = ({
	title = 'are-you-sure?',
	children,
	extra,
	callback,
}) => {
	const { t } = useTranslate();
	const [open, setOpen] = useState(false);

	return (
		<div className='inline-block'>
			<Popover
				open={open}
				zIndex={'9999999999'}
				content={
					<div className='flex flex-col gap-2'>
						{extra}

						<div className='flex items-center justify-stretch gap-4'>
							<Button
								onClick={() => {
									callback();
									setOpen(false);
								}}
								className='grow'
							>
								{t('yes')}
							</Button>
							<Button
								onClick={() => {
									setOpen(false);
								}}
								className='grow'
							>
								{t('cancel')}
							</Button>
						</div>
					</div>
				}
				title={<Text className='text-black dark:text-white' tid={t(title)} />}
				trigger='click'
			>
				<span onClick={() => setOpen(true)}>{children}</span>
			</Popover>
		</div>
	);
};
