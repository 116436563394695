import {
	SupportButton,
	SupportIcon,
	SupportWrapper,
	AnimatedDot,
	AnimatedDotsWrapper,
} from '../utils/Styles';
import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import SupportBox from './SupportBox';
import useScrollDirection from 'core/hooks/useScrollDirection';

const SupportChat = () => {
	const scrollDirection = useScrollDirection();

	const [boxOpen, setBoxOpen] = useState(false);

	const onSupportBtnClicked = () => {
		setBoxOpen(true);
	};

	const onBoxClose = () => setBoxOpen(false);

	return (
		<SupportWrapper direction={scrollDirection}>
			<SupportButton onClick={onSupportBtnClicked}>
				<SupportIcon size={26} />
				<AnimatedDotsWrapper>
					{[1, 2, 3].map((item) => (
						<AnimatedDot key={item} idx={item} />
					))}
				</AnimatedDotsWrapper>
			</SupportButton>
			<AnimatePresence exitBeforeEnter>
				{boxOpen && <SupportBox onClose={onBoxClose} />}
			</AnimatePresence>
		</SupportWrapper>
	);
};

export default SupportChat;
