import { Button, Popover } from 'antd';
import useTranslate from 'core/utils/useTranslate';
import { FC } from 'react';
import { FaInfoCircle } from 'react-icons/fa';

interface IText {
	hintTitle?: string;
	hintDescription?: string;
}

export const HintBox: FC<IText> = ({ hintDescription, hintTitle }) => {
	const { t } = useTranslate();

	// // console.log(t(hintDescription));

	return (
		<Popover
			content={
				<div>
					<p className='max-w-xs'>
						{hintDescription?.length
							? t(hintDescription)
								? t(hintDescription)
								: undefined
							: undefined}
					</p>
				</div>
			}
			title={hintTitle?.length ? t(hintTitle) : undefined}
			trigger='hover'
			className='z-[99999]'
			overlayClassName='z-[99999]'
			openClassName='z-[99999]'
			zIndex={99999}
		>
			<Button
				className='inline-flex ms-1 items-center justify-center relative z-[99999]'
				icon={<FaInfoCircle />}
				type='default'
				shape='circle'
				size='small'
			></Button>
		</Popover>
	);
};
