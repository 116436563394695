import { FC, ReactNode } from 'react';
import ReduxProvider from './redux/ReduxProvider';
import ReactQueryProviders from './react-query/ReactQueryProviders';
import ToastProvider from './toast/ToastProvider';
import AppProvider from './app/AppProvider';
import { ConfigProvider } from 'antd-country-phone-input';

import en from 'world_countries_lists/data/countries/en/world.json';

// Usually you only need to import ConfigProvider & CSS once in App.js/App.tsx
// CSS order is important!
// import 'antd/dist/antd.css';
import 'antd-country-phone-input/dist/index.css';

interface IIndexProviders {
	children: ReactNode;
}

const IndexProviders: FC<IIndexProviders> = ({ children }) => {
	return (
		<ConfigProvider locale={en}>
			<ReduxProvider>
				<ToastProvider />
				<ReactQueryProviders>
					<AppProvider>{children}</AppProvider>
				</ReactQueryProviders>
			</ReduxProvider>
		</ConfigProvider>
	);
};

export default IndexProviders;
