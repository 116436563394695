import Text from 'core/utils/Text';
import { FC } from 'react';
import DgButton from 'styles/UI/Button/DgButton';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useNavigate } from 'react-router-dom';
import { routes } from 'core/constants/routes';
import useGoogle2FA from 'components/Profile/hooks/useGoogle2FA';
import DgModal from 'styles/UI/Modal/DgModal';
import DisableGoogle2FAForm from './DisableGoogle2FAForm';

const ActiveGoogle2FA: FC = () => {
	const { currentUserInfo } = useSelector((store: RootState) => store.userInfoStore);

	const navigate = useNavigate();

	const {
		show2FAModal,
		modal2FA,
		hide2FAModal,
		onFinishGoogle2FA,
		isLoadingGoogle2FA,
		disableGoogle2FAForm,
	} = useGoogle2FA();

	return (
		<>
			<DgModal open={modal2FA} onCancel={hide2FAModal} title='disable-totp-verification'>
				<DisableGoogle2FAForm
					onFinishGoogle2FA={onFinishGoogle2FA}
					isLoadingGoogle2FA={isLoadingGoogle2FA}
					disableGoogle2FAForm={disableGoogle2FAForm}
				/>
			</DgModal>
			<div className='border-b border-b-[var(--dg-border-color)] pb-3'>
				<div className='flex justify-between items-center flex-wrap'>
					<Text tid='totp-verification' />

					<DgButton
						className='min-w-[18rem]'
						danger={!!currentUserInfo?.data?.google2fa_enable}
						title={
							currentUserInfo?.data.google2fa_enable
								? 'disable-totp-verification'
								: 'set-totp-verification'
						}
						onClick={() =>
							!currentUserInfo?.data?.google2fa_enable
								? navigate(routes.PROFILE_SECURITY_ACTIVE_2FA)
								: show2FAModal()
						}
					/>
				</div>
			</div>
		</>
	);
};

export default ActiveGoogle2FA;
