import { useState, useEffect } from 'react';

const useScrollDirection = () => {
	const [scrollDirection, setScrollDirection] = useState(null);
	const [lastScrollTop, setLastScrollTop] = useState(0);

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

			if (currentScrollTop > lastScrollTop) {
				setScrollDirection('down');
			} else {
				setScrollDirection('up');
			}

			setLastScrollTop(currentScrollTop);
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [lastScrollTop]);

	return scrollDirection;
};

export default useScrollDirection;
